import './App.css';
import Header from './components/header1/Header';
import QuienesSom from './components/quienesSomos1/QuienesSom';
import QuienesSom2 from './components/quienesSomos2/QuienesSom2';
import Drag from "./components/drag/Drag"
import Footer from './components/footer1/Footer';
import Servicios from './components/servicios/Servicios';
import Profesionales from './components/profesionales/Profesionales';
import Contacto from './components/contacto/Contacto';
import Consultas from './components/consultas/Consultas';

function App() {
  
  return (
    <div className="App">
      <Header />

      <main>
        <>
          <QuienesSom />
          <QuienesSom2 />
          <Servicios />
          <Profesionales/>
          <Contacto />
          <Consultas/>
          <Drag />
          <a className='wspButton' href='https://wa.me/34677623910' target='__blank'>
            <svg width="57" height="53" viewBox="0 0 57 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48.6935 7.71164C46.0675 5.25755 42.9396 3.31177 39.4922 1.98779C36.0449 0.663807 32.3471 -0.0118723 28.6146 0.000157859C12.9754 0.000157859 0.229146 11.7926 0.229146 26.2616C0.229146 30.8991 1.54673 35.4041 4.01005 39.379L0 53L15.0377 49.343C19.191 51.4365 23.8598 52.5495 28.6146 52.5495C44.2538 52.5495 57 40.757 57 26.2881C57 19.2656 54.0497 12.6671 48.6935 7.71164ZM28.6146 48.0975C24.3754 48.0975 20.2221 47.0375 16.5844 45.05L15.7251 44.573L6.78844 46.746L9.16583 38.69L8.59297 37.8685C6.23777 34.389 4.98719 30.3671 4.98392 26.2616C4.98392 14.2306 15.5819 4.42565 28.5859 4.42565C34.8874 4.42565 40.8166 6.70464 45.2563 10.8386C47.4546 12.8631 49.1967 15.2712 50.3817 17.9232C51.5666 20.5752 52.1708 23.4184 52.1593 26.2881C52.2166 38.319 41.6186 48.0975 28.6146 48.0975ZM41.5613 31.7736C40.8452 31.4556 37.3507 29.8656 36.7206 29.6271C36.0618 29.4151 35.6035 29.3091 35.1166 29.9451C34.6296 30.6076 33.2834 32.0916 32.8824 32.5156C32.4814 32.9661 32.0518 33.0191 31.3357 32.6746C30.6196 32.3566 28.3281 31.6411 25.6357 29.4151C23.5161 27.6661 22.1126 25.5196 21.6829 24.8571C21.2819 24.1946 21.6256 23.8501 21.998 23.5056C22.3131 23.2141 22.7141 22.7371 23.0578 22.3661C23.4015 21.9951 23.5447 21.7036 23.7739 21.2796C24.003 20.8291 23.8884 20.4581 23.7166 20.1401C23.5447 19.8221 22.1126 16.5891 21.5397 15.2641C20.9668 13.9921 20.3653 14.1511 19.9357 14.1246H18.5608C18.0739 14.1246 17.3291 14.2836 16.6704 14.9461C16.0402 15.6086 14.207 17.1986 14.207 20.4316C14.207 23.6646 16.7563 26.7916 17.1 27.2156C17.4437 27.6661 22.1126 34.2911 29.2161 37.1265C30.906 37.8155 32.2236 38.213 33.2548 38.5045C34.9447 39.008 36.4915 38.9285 37.7231 38.7695C39.098 38.584 41.9337 37.1795 42.5065 35.6426C43.108 34.1056 43.108 32.8071 42.9075 32.5156C42.707 32.2241 42.2774 32.0916 41.5613 31.7736Z" fill="white"/>
            </svg>
          </a> 

        </>
      </main>

      <Footer/>
    </div>
  );
}

export default App;
